<template>
  <div>
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
      style="float: right;"
    >
      <template #toggler>
        <CHeaderNavLink>
          <span style="height: 20px;">{{user_name}}
          &nbsp;&nbsp;&nbsp;
          <CIcon :height="20" name="cil-cog" /></span>
        </CHeaderNavLink>
      </template>
      <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>Mi Cuenta</strong>
      </CDropdownHeader>
      <CDropdownItem href="/#/products">
        <CIcon name="cil-group" /> Productos
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem href="/#/sale">
        <CIcon name="cil-group" /> Nueva Venta
      </CDropdownItem>
      <CDropdownItem href="/#/sales">
        <CIcon name="cil-group" /> Todas las Ventas
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem href="/#/presale">
        <CIcon name="cil-group" /> Nueva Venta
      </CDropdownItem>
      <CDropdownItem href="/#/presales">
        <CIcon name="cil-group" /> Todas las Ventas
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem href="/#/payments">
        <CIcon name="cil-group" /> Pagos Recibidos
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem @click="logout">
        <CIcon name="cil-lock-locked" /> Cerrar Sesión
      </CDropdownItem>
    </CDropdown>
  </div>
</template>

<script>
import router from '../router/index'
import store from '../store'
import account_ws from '../services/account';
import ws from '../services/general';

export default {
  name: 'TheHeaderDropdownMobile',
  data () {
    return { 
      name: '',
      user_name: '',
      menu: []
    }
  },
  mounted: async function() {
    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.name = response.data.name;
      this.user_name = response.data.user.first_name+" "+response.data.user.last_name;
    }

    let response2 = await ws.getMenu("header"); 

    if(response2.type == "success"){
      this.menu = response2.data;
    }
  },
  methods: {
    logout: function(){
      localStorage.removeItem("token");
      store.commit('clear');
      router.push("login");
    },
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>