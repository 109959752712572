<template>
  <CFooter :fixed="false">
    <div>
      <a :href="url" target="_blank">{{name}}</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}<br />{{slogan}}</span>
    </div>
    <div class="mfs-auto"></div>
  </CFooter>
</template>

<script>
import store from '../store'
import ws from '../services/general';

export default {
  name: 'TheFooter',
  data() {
      return {
        name: '',
        url: '/',
        slogan: '',
        menu: []
      }
  },
  mounted: async function() {
      setTimeout(() => {
        store.commit("getParameter", "PROJECT_NAME")
        this.name = store.state.parameter;

        store.commit("getParameter", "PROJECT_URL")
        this.url = store.state.parameter;

        store.commit("getParameter", "PROJECT_SLOGAN")
        this.slogan = store.state.parameter;
      }, 500);

      // let response = await ws.getMenu("footer"); 

      // if(response.type == "success"){
      //   this.menu = response.data;
      // }
  }, 
}
</script>
